import React from 'react'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import * as router from '../../../services/router'
import History from '../../history/list/History'
import { loadUser } from '../../../actions/userActions'
import './User.css'
import { getDate } from '../../../utils/utils'
import { Top } from '../../header/Top'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: '40px',
    height: '40px',
  },
  large: {
    width: '200px',
    height: '200px',
  },
  menu: {
    //backgroundColor: COLOR_LIGHT_TEXT,
  },
  userLabel: {
    fontSize: '14px',
    fontWeight: '500',
    //letterSpacing: '0.5px',
    marginRight: '10px',
  },
}))

const UserPresentation = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const editUser = (id) => dispatch(push(router.ROUTE_USER_EDIT + id))
  const classes = useStyles()

  return (
    <div className="profile">
      <Top title="Profile" />
      <div className="content-inside-margin">
        <div className="centered-items">
          <div className="profile-pic">
            <Avatar className={classes.large} src={user.avatar} />
          </div>
          <div className="name">{user.name}</div>
        </div>
        <div className="form-top left-right-block flex-box-centered shadow">
          <div className="">Created: {getDate(user.created_at)}</div>
          <div className="flex-box-centered">
            <TextButton color="primary" onClick={() => editUser(id)} label="Edit" />
          </div>
        </div>
        <h1>Edit log</h1>
        <History userId={id} />
      </div>
    </div>
  )
}

const User = () => {
  const loading = useSelector((state) => state.user.state)
  const error = useSelector((state) => state.user.error)
  const watch = 'id'
  const dispatch = useDispatch()
  const { id } = useParams()
  const load = () => dispatch(loadUser(id))

  return (
    <DataProviderFunctional watch={watch} error={error} load={load} loading={loading} component={UserPresentation} />
  )
}

export default User
