import * as React from 'react'
import FormTop from '../form/FormTop'
import { EditTop } from './EditTop'
import NavigationPrompt from './NavigationPrompt'

export const Form = (props) => (
  <div className="page-content">
    <div className="form-controls">
      <EditTop title={props.title} remove={props.onRemove} />
      <div className="form-controls__bottom">
        <FormTop
          title={props.title}
          button="Save"
          remote={props.remoteSubmit}
          saveState={props.saveState}
          remove={props.onRemove}
        />
        <div className="row flex-box"></div>
      </div>
    </div>
    <div className="artwork-grid">
      {/* <NavigationPrompt when={props.dirty} /> */}
      <props.formComponent {...props} />
    </div>
  </div>
)
